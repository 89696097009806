package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.icons.MoonIcon
import com.varabyte.kobweb.silk.components.icons.SunIcon
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCalculate
import com.varabyte.kobweb.silk.components.icons.mdi.MdiEmail
import com.varabyte.kobweb.silk.components.icons.mdi.MdiPhone
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.overlay.PopupPlacement
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.defer.deferRender
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.ecolution.webpage.components.widgets.ButtonShape
import org.ecolution.webpage.components.widgets.TransparentButton
import org.ecolution.webpage.components.widgets.LinkButton
import org.ecolution.webpage.components.widgets.CustomButton
import org.ecolution.webpage.toSitePalette
import org.ecolution.webpage.data.Company
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun ColorModeButton() {
    val breakpoint = rememberBreakpoint()

    if (breakpoint >= Breakpoint.MD) {
        ColorModeDesktopButton()
    } else {
        ColorModeMobileButton()
    }
}

@Composable
fun ColorModeDesktopButton() {
    var colorMode by ColorMode.currentState
    val iconColor = ColorMode.current.toSitePalette().text

    CustomButton(onClick = { colorMode = colorMode.opposite }, shape = ButtonShape.CIRCLE) {
        if (colorMode.isLight) MoonIcon(Modifier.color(iconColor)) else SunIcon(Modifier.color(iconColor))
    }
    Tooltip(
        ElementTarget.PreviousSibling,
        "Dark/Light Mode",
        Modifier.displayIfAtLeast(Breakpoint.MD),
        placement = PopupPlacement.BottomRight
    )
}

@Composable
fun ColorModeMobileButton() {
    var colorMode by ColorMode.currentState
    val iconColor = ColorMode.current.toSitePalette().text

    Box(modifier = Modifier
        .onClick { colorMode = colorMode.opposite }
        .backgroundColor(ColorMode.current.toSitePalette().primaryButton)
        .borderRadius(50.percent)
        .padding(9.px)
    ) {
        if (colorMode.isLight) MoonIcon(Modifier.color(iconColor)) else SunIcon(Modifier.color(iconColor))
    }
}

@Composable
fun CalculatorButton() {
    val iconColor = ColorMode.current.toSitePalette().text

    LinkButton(Company.Details.CALC_URL, shape = ButtonShape.CIRCLE) {
        MdiCalculate(Modifier.color(iconColor))
    }
    Tooltip(
        ElementTarget.PreviousSibling, "Preis Rechner / Bestellen",
        Modifier.displayIfAtLeast(Breakpoint.MD),
        placement = PopupPlacement.BottomRight
    )
}

@Composable
fun EmailButton() {
    val iconColor = ColorMode.current.toSitePalette().text

    CustomButton(
        onClick = { window.location.href = Company.Details.EMAIL },
        shape = ButtonShape.CIRCLE
    ) {
        MdiEmail(Modifier.color(iconColor))
    }
    Tooltip(
        ElementTarget.PreviousSibling,
        "info(at)ecolution.swiss",
        Modifier.displayIfAtLeast(Breakpoint.MD),
        placement = PopupPlacement.BottomRight
    )
}

@Composable
fun PhoneButton() {
    val iconColor = ColorMode.current.toSitePalette().text

    CustomButton(
        onClick = { window.location.href = "tel:0565522200" },
        shape = ButtonShape.CIRCLE
    ) {
        MdiPhone(Modifier.color(iconColor))
    }
    Tooltip(
        ElementTarget.PreviousSibling,
        "056 552 22 00",
        Modifier.displayIfAtLeast(Breakpoint.MD),
        placement = PopupPlacement.BottomRight
    )

}


@Composable
fun HamburgerButton(onClick: () -> Unit) {
    TransparentButton(onClick = onClick, icon = { HamburgerIcon(it) })
}

@Composable
fun CloseButton(onClick: () -> Unit) {
    TransparentButton(onClick = onClick, icon = { CloseIcon(it) })
}

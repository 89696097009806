package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun Blog1Page() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("blog1") {
        org.jetbrains.compose.web.dom.H3(attrs = { id("blog-1") }) {
            org.jetbrains.compose.web.dom.Text("BLOG 1")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(org.ecolution.webpage.data.Blog.Companion.blogs[0])
    }
}

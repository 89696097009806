package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun FoundationPage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("Gründung") {
        org.ecolution.webpage.components.widgets.EcoSpacer()
        org.jetbrains.compose.web.dom.H3(attrs = { id("die-gründung-von-ecolution-ein-abenteuer-im-camper") }) {
            org.jetbrains.compose.web.dom.Text("Die Gründung von Ecolution: Ein Abenteuer im Camper")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-traum-wurde-geboren") }) {
            org.jetbrains.compose.web.dom.Text("Ein Traum wurde geboren")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Geburtsstunde von Ecolution schlug am ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("10. Juni 2019")
            }
            org.jetbrains.compose.web.dom.Text(" in ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Silverthorne, Colorado, USA")
            }
            org.jetbrains.compose.web.dom.Text(", als den drei Schweizer Gründern während der Fahrt von Denver nach Silverthorne die beste Idee ihres Lebens kam. Diese Eingebung war so kraftvoll, dass sie die Idee sofort gemeinsam in die Tat umsetzten und begannen, in einem Restaurant einen Businessplan zu erstellen. Dieser wurde später weiter ausgearbeitet und verfeinert. Es war ein Moment der Erleuchtung und Manifestation zugleich – die Idee entstand und wurde gleichzeitig realisiert.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-moment-der-inspiration") }) {
            org.jetbrains.compose.web.dom.Text("Ein Moment der Inspiration")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Geschichte von Ecolution begann nicht in einem schicken Bürogebäude oder einem innovativen Co-Working-Space, sondern an einem Ort, der die Werte unseres Unternehmens wie kein anderer verkörpert: die weite der unberührten Natur. Inmitten der atemberaubenden Landschaften der Rocky Mountains, umgeben von nichts als frischer Luft, Wasser und der Ruhe der Wildnis, entstand die Vision, die den Grundstein für Ecolution legte.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Es war im Sommer 2019, während eines Roadtrips in einem Camper von San Francisco bis nach Colorado, als die Idee zu Ecolution wurde. Wir sassen am Feuer neben unserem Camper, unter dem sternenklaren Himmel und sprachen über die Vision, unser Leben und die Freundschaft.")
        }
        org.ecolution.webpage.components.widgets.FoundationMap()
        org.jetbrains.compose.web.dom.H4(attrs = { id("die-vision") }) {
            org.jetbrains.compose.web.dom.Text("Die Vision")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ecolution steht für eine Revolution im Bauwesen. Unsere Mission ist es, innovative und nachhaltige Lösungen für Gebäude zu entwickeln, die den Energieverbrauch minimieren und die Umwelt schonen. Von energieeffizienten Wohnhäusern bis hin zu grossen nachhaltigen Bauprojekten – wir wollen die Zukunft des Bauens mitgestalten.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Unsere Reise begann in einem bescheidenen Camper, aber unsere Vision ist global. Mit einem Team von Experten, das sich der Idee verschrieben hat, die Welt zu einem besseren Ort zu machen, arbeiten wir täglich daran, diese Vision Realität werden zu lassen.")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(org.ecolution.webpage.data.Blog.Companion.blogs[1])
    }
}

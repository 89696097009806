package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.dom.ElementRefScope
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.KeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.ManualKeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.manual
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Navigation.ABOUT_NAME
import org.ecolution.webpage.data.Navigation.ABOUT_URL
import org.ecolution.webpage.data.Navigation.REFERENCES_NAME
import org.ecolution.webpage.data.Navigation.REFERENCES_URL
import org.ecolution.webpage.data.Navigation.SERVICES_NAME
import org.ecolution.webpage.data.Service
import org.ecolution.webpage.data.Service.Companion.SubNavigation.subServices
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.cssRem
import org.w3c.dom.HTMLElement


@Composable
fun MenuItems(closeMenu: () -> Unit) {
    val keepOpenStrategy = remember { KeepPopupOpenStrategy.manual() }

    MultiMenuText(SERVICES_NAME, keepOpenStrategy = keepOpenStrategy)
    MultiMenu(
        target = ElementTarget.PreviousSibling,
        services = subServices,
        keepOpenStrategy = keepOpenStrategy
    )
    BasicNavLinks(closeMenu)
}

@Composable
fun SideMenuItems(closeMenu: () -> Unit) {
    ExpandableMenu(SERVICES_NAME, subServices, closeMenu)
    BasicNavLinks(closeMenu)
}

@Composable
private fun BasicNavLinks(closeMenu: () -> Unit) {
    NavLink(REFERENCES_URL, REFERENCES_NAME, closeMenu)
    NavLink(ABOUT_URL, ABOUT_NAME, closeMenu)
}

@Composable
private fun ExpandableMenu(text: String, subMenus: List<Service>, closeMenu: () -> Unit) {
    var isExpanded by remember { mutableStateOf(false) }
    val (colorModes, currentTextColor) = getColorAndCurrentTextColor()
    val (textColor, oppositeTextColor) = colorModes

    Column {
        SpanText(
            text = text,
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .color(currentTextColor.value)
                .onMouseEnter { currentTextColor.value = oppositeTextColor }
                .onMouseLeave { currentTextColor.value = textColor }
                .onClick { isExpanded = !isExpanded }
        )

        if (isExpanded) {
            subMenus.forEach { subMenu ->
                Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.CenterEnd) {
                    SubNavLink(path = subMenu.urlPath, text = subMenu.name, closeMenu)
                }
            }
        }
    }
}

@Composable
fun MultiMenuText(
    text: String? = null,
    ref: ElementRefScope<HTMLElement>? = null,
    keepOpenStrategy: ManualKeepPopupOpenStrategy
) {
    val (colorModes, currentTextColor) = getColorAndCurrentTextColor()
    val (textColor, oppositeTextColor) = colorModes

    Column {
        SpanText(
            text ?: "",
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .color(currentTextColor.value)
                .onMouseEnter { currentTextColor.value = oppositeTextColor }
                .onMouseLeave {
                    currentTextColor.value = textColor
                    keepOpenStrategy.shouldKeepOpen = false
                },
            variant = UndecoratedLinkVariant.then(UncoloredLinkVariant),
            ref = ref
        )
    }
}

@Composable
private fun SubNavLink(path: String, text: String, closeMenu: () -> Unit) {
    NavLink(path, text, closeMenu, Modifier.fontSize(1.cssRem).padding(0.2.cssRem))
}

@Composable
private fun NavLink(path: String, text: String, closeMenu: () -> Unit, modifier: Modifier = Modifier) {
    val (colorModes, currentTextColor) = getColorAndCurrentTextColor()
    val (textColor, oppositeTextColor) = colorModes

    Link(
        path = path,
        modifier = Modifier
            .onMouseEnter { currentTextColor.value = oppositeTextColor }
            .onMouseLeave { currentTextColor.value = textColor }
            .onClick { closeMenu() }
            .then(modifier),
        variant = UndecoratedLinkVariant.then(UncoloredLinkVariant)
    ) {
        SpanText(text = text,
            modifier = Modifier.color(currentTextColor.value),
            variant = UndecoratedLinkVariant.then(UncoloredLinkVariant))
    }
}

@Composable
private fun getColorAndCurrentTextColor(): Pair<Pair<Color, Color>, MutableState<Color>> {
    val colorMode = ColorMode.current
    val colorModes = when (colorMode) {
        ColorMode.DARK -> Pair(ColorMode.current.toSitePalette().navLinkOn, ColorMode.current.toSitePalette().navLinkOff)
        ColorMode.LIGHT -> Pair(ColorMode.current.toSitePalette().navLinkOff, ColorMode.current.toSitePalette().navLinkOn)
    }
    val currentTextColor = remember(colorMode) { mutableStateOf(colorModes.first) }
    return Pair(colorModes, currentTextColor)
}
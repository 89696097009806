package org.ecolution.webpage.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiCalculate
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.SubheadlineTextStyle
import org.ecolution.webpage.components.layouts.HalfPageContentStyle
import org.ecolution.webpage.components.widgets.HeaderTitle
import org.ecolution.webpage.components.widgets.LinkButton
import org.ecolution.webpage.data.Employee.Companion.CEO
import org.ecolution.webpage.data.Employee.Companion.Employee.KI_IMAGE
import org.ecolution.webpage.data.Employee.Companion.Employee.KI_NAME
import org.ecolution.webpage.toSitePalette
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Company.Details.WELCOME_TEXT
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Composable
fun WelcomeSection() {
    val breakpoint = rememberBreakpoint()
    if (breakpoint < Breakpoint.MD) {
        WelcomeSmall()
    } else {
        WelcomeLarge()
    }
}

@Composable
fun WelcomeSmall() {
    Box(
        modifier = HalfPageContentStyle.toModifier(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            HeaderTitle(
                modifier = Modifier.margin(top = 1.5.em, bottom = 0.em),
                title = "Willkommen bei"
            )
            HeaderTitle(
                modifier = Modifier.margin(bottom = 1.em),
                title = "${Company.Details.FULL_NAME},"
            )
            Row(WelcomeHeaderContainerStyle.toModifier()) {
                WelcomeTextAndLink()
            }
        }
    }
}

@Composable
fun WelcomeLarge() {
    Box(
        modifier = HalfPageContentStyle.toModifier(),
        contentAlignment = Alignment.Center
    ) {
        Column(
            Modifier.fillMaxWidth(),
        ) {
            HeaderTitle(
                modifier = Modifier.margin(top = 1.5.em, bottom = 0.em),
                title = "Willkommen bei ${Company.Details.FULL_NAME},"
            )
            Row(WelcomeHeaderContainerStyle.toModifier()) {
                WelcomeTextAndLink()
                Spacer()
                CEOImageAndName()
            }
        }
    }
}

@Composable
fun WelcomeTextAndLink() {
    val color = ColorMode.current.toSitePalette().text
    val breakpoint = rememberBreakpoint()

    val buttonAlign = if (breakpoint < Breakpoint.SM) Alignment.CenterHorizontally else Alignment.Start

    Box(Modifier.width(600.px)) {
        Column(Modifier.gap(2.cssRem), horizontalAlignment = buttonAlign) {
            Div(SubheadlineTextStyle.toAttrs()) {
                SpanText(
                    WELCOME_TEXT, Modifier.display(DisplayStyle.Block).textAlign(TextAlign.Justify)
                )
            }
            LinkButton(Company.Details.CALC_URL, Modifier.width(200.px), "Preis Rechner") {
                MdiCalculate(Modifier.margin(right = 8.px).color(color))
            }
        }
    }
}

@Composable
fun CEOImageAndName() {
    Box(contentAlignment = Alignment.Center) {
        Column {
            Img(
                src = KI_IMAGE, alt = KI_NAME, Modifier.height(260.px).toAttrs()
            )
            SpanText(
                text = "${KI_NAME}, $CEO",
                Modifier.fontStyle(FontStyle.Italic).fontWeight(FontWeight.Light).fontSize(15.px)
                    .margin(bottom = 0.5.cssRem, top = 0.5.cssRem, left = 1.5.cssRem)
            )
        }
    }
}

val WelcomeHeaderContainerStyle by ComponentStyle { // Container that has a tagline and grid on desktop, and just the tagline on mobile
    base { Modifier.fillMaxWidth() }
    Breakpoint.MD { Modifier.margin { top(5.vh) } }
}
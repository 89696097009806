package org.ecolution.webpage.data

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.icons.mdi.*
import kotlinx.datetime.LocalDate


data class Blog(
    var id: String,
    var title: String,
    val author: String,
    val tags: List<String>,
    val description: String,
    val contentPath: String,
    val imgSrc: String,
    val publishedDate: LocalDate,
    val updatedDate: LocalDate? = null,
    val readTime: Int,   // in minutes
    val isPublished: Boolean = true,
    val icon: @Composable (Modifier) -> Unit
) {

    companion object {

        val blogs = listOf(
            Blog(
                id = "4",
                title = "Offizieller Partner",
                author = "Ecolution Engineering",
                tags = listOf("Partnerschaft"),
                description = "Ecolution und die Stadt Baden arbeiten zusammen, um nachhaltige Energielösungen voranzutreiben.",
                contentPath = "/baden",
                imgSrc = "blogs/baden.webp",
                publishedDate = LocalDate(2024, 9, 13),
                readTime = 3,
                icon = { modifier -> MdiHandshake(modifier) }
            ),
            Blog(
                id = "3",
                title = "Fokus auf Altbauten",
                author = "Ecolution Engineering",
                tags = listOf("Energieberatung", "Umstrukturierung"),
                description = "Vom HLKS-Allrounder zum Experten für Energieberatung und Gebäudeoptimierung.",
                contentPath = "/transformation",
                imgSrc = "blogs/transformation.webp",
                publishedDate = LocalDate(2024, 7, 15),
                readTime = 3,
                icon = { modifier -> MdiEnergySavingsLeaf(modifier) }
            ),
            Blog(
                id = "2",
                title = "Unser Bürostandort",
                author = "Ecolution Engineering",
                tags = listOf("Büroumzug"),
                description = "Am 1. November 2021 haben wir unser neues Büro in Ennetbaden bezogen.",
                contentPath = "/office",
                imgSrc = "blogs/hq2.webp",
                publishedDate = LocalDate(2021, 11, 1),
                readTime = 5,
                icon = { modifier -> MdiHouse(modifier) }
            ),
            Blog(
                id = "1",
                title = "Gründung von Ecolution",
                author = "Ecolution Engineering",
                tags = listOf("Gründung", "Freundschaft"),
                description = "Ecolution wurde in einem Camper in den wilden Landschaften Colorados gegründet.",
                contentPath = "/foundation",
                imgSrc = "blogs/camper.webp",
                publishedDate = LocalDate(2019, 6, 10),
                readTime = 4,
                icon = { modifier -> MdiRocketLaunch(modifier) }
            )
        )
    }
}
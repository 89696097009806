package org.ecolution.webpage.pages.blogs

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun BLOG1Page() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("BLOG1") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("blog-1") }) {
            org.jetbrains.compose.web.dom.Text("BLOG 1")
        }
    }
}

package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.*
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

enum class ButtonShape {
    RECTANGLE,
    CIRCLE
}

private fun getButtonModifier(shape: ButtonShape): Modifier {
    return Modifier.padding(0.px).then(if (shape == ButtonShape.CIRCLE) {
        Modifier.borderRadius(50.percent)
    } else {
        Modifier.padding(12.px).borderRadius(8.px).padding(bottom = 0.5.px)
    })
}


@Composable
fun CustomButton(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    text: String? = null,
    shape: ButtonShape = ButtonShape.RECTANGLE,
    content: @Composable () -> Unit = {}
) {
    val color = ColorMode.current.toSitePalette().text

    Button(
        onClick = { onClick() },
        modifier.then(getButtonModifier(shape)),
    ) {
        Row(verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        ) {
            content()
            if (!text.isNullOrEmpty()) {
                SpanText(text, Modifier.align(Alignment.CenterVertically).color(color))
            }
        }
    }
}

/**
 * Create a [CustomButton] which acts likes a link, navigating to some target URL when clicked on.
 */
@Composable
fun LinkButton(
    path: String,
    modifier: Modifier = Modifier,
    text: String? = null,
    shape: ButtonShape = ButtonShape.RECTANGLE,
    content: @Composable () -> Unit = {}
) {
    val ctx = rememberPageContext()

    CustomButton(onClick = { ctx.router.navigateTo(path) }, modifier, text, shape, content)
}

@Composable
fun TransparentButton(
    onClick: () -> Unit,
    icon: @Composable (Modifier) -> Unit
) {
    var isHovered by remember { mutableStateOf(false) }
    val transparentButtonIconColor = ColorMode.current.toSitePalette().transparentButtonIcon
    val transparentButtonHoverColor = ColorMode.current.toSitePalette().transparentButtonHover
    val colorMode = ColorMode.current

    Button(
        onClick = {
            onClick()
            isHovered = false
                  },
        modifier = Modifier
            .setVariable(ButtonVars.FontSize, 1.em) // Make button icon size relative to parent container font size
            .onMouseEnter { isHovered = true }
            .onMouseLeave { isHovered = false }
            .backgroundColor(if (isHovered) transparentButtonHoverColor else Color.transparent),
        variant = CircleButtonVariant.then(UncoloredButtonVariant)
    ) {
        if (colorMode.isLight && isHovered) icon(Modifier.color(transparentButtonIconColor)) else icon(Modifier)
    }
}

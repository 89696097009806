package org.ecolution.webpage.components.header

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.defer.deferRender
import com.varabyte.kobweb.silk.theme.breakpoint.breakpointFloor
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.ecolution.webpage.toSitePalette
import org.w3c.dom.events.Event

val NavHeaderStyle by ComponentStyle.base {
    Modifier
        .fillMaxWidth()
        .padding(topBottom = 1.cssRem, leftRight = 2.cssRem)
        .position(Position.Sticky)
        .top(0.percent)
    //.backdropFilter(saturate(180.percent), blur(5.px))
}

val SideMenuSlideInAnim by Keyframes {
    from {
        Modifier.translateY(100.percent)
    }

    to {
        Modifier
    }
}

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun NavHeader() {
    var windowBreakpoint by remember { mutableStateOf(window.breakpointFloor) }
    var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }
    val closeMenu = { menuState = menuState.close() }
    val color = ColorMode.current.toSitePalette().navHeaderBackground

    DisposableEffect(Unit) { // to close the SideMenu on window resize +
        val onResize: (Event) -> Unit = {
            windowBreakpoint = window.breakpointFloor
        }

        window.addEventListener("resize", onResize)

        onDispose {
            window.removeEventListener("resize", onResize)
        }
    }

    deferRender {
        Row(NavHeaderStyle.toModifier().backgroundColor(color), verticalAlignment = Alignment.CenterVertically) {
            // Block display overrides inline display of the <img> tag, so it calculates centering better
            HomeLogo(ColorMode.current)

            Spacer()

            Row(
                Modifier.gap(1.5.cssRem).displayIfAtLeast(Breakpoint.LG),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (menuState == SideMenuState.CLOSED) {
                    MenuItems(closeMenu = {})
                    CalculatorButton()
                    EmailButton()
                    PhoneButton()
                    ColorModeButton()
                }
            }

            Row(
                Modifier
                    .fontSize(1.5.cssRem)
                    .gap(1.cssRem)
                    .displayUntil(Breakpoint.LG),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (windowBreakpoint >= Breakpoint.MD && menuState != SideMenuState.OPEN) {
                    CalculatorButton()
                    EmailButton()
                    PhoneButton()
                    ColorModeButton()
                }
                HamburgerButton(onClick = { menuState = SideMenuState.OPEN })

                if (menuState != SideMenuState.CLOSED && windowBreakpoint >= Breakpoint.LG) {
                    menuState = SideMenuState.CLOSED
                }

                if (menuState != SideMenuState.CLOSED) {
                    SideMenu(
                        menuState,
                        closeMenu = closeMenu,
                        onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
                    )
                }
            }
        }
    }
}

import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { org.ecolution.webpage.pages.HomePage() }
        ctx.router.register("/about") { org.ecolution.webpage.pages.AboutPage() }
        ctx.router.register("/energienachweis") { org.ecolution.webpage.pages.EnergienachweisPage()
                }
        ctx.router.register("/example") { org.ecolution.webpage.pages.ExamplePage() }
        ctx.router.register("/geak") { org.ecolution.webpage.pages.GeakPage() }
        ctx.router.register("/gebaeudeanalyse") { org.ecolution.webpage.pages.GebaeudeanalysePage()
                }
        ctx.router.register("/impulsberatung") { org.ecolution.webpage.pages.ImpulsberatungPage() }
        ctx.router.register("/minergienachweis") {
                org.ecolution.webpage.pages.MinergienachweisPage() }
        ctx.router.register("/references") { org.ecolution.webpage.pages.ReferencesPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.HeadlineTextStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.SubheadlineTextStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.header.NavHeaderStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.layouts.MarkdownStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.layouts.HalfPageContentStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.layouts.FullPageContentStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.sections.WelcomeHeaderContainerStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.sections.service.FAQHalfPageContentStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.widgets.SectionStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.widgets.CenterAlignedStyle)
        ctx.theme.registerComponentStyle(org.ecolution.webpage.components.widgets.SubTitleStyle)
        ctx.theme.registerComponentVariants(org.ecolution.webpage.CircleButtonVariant)
        ctx.theme.registerComponentVariants(org.ecolution.webpage.UncoloredButtonVariant)
        ctx.theme.registerComponentVariants(org.ecolution.webpage.components.widgets.SubTitleVariant)
        ctx.theme.registerComponentVariants(org.ecolution.webpage.components.widgets.HeaderTitleVariant)
        ctx.stylesheet.registerKeyframes(org.ecolution.webpage.components.header.SideMenuSlideInAnim)
        org.ecolution.webpage.initColorMode(ctx)
        org.ecolution.webpage.initSiteStyles(ctx)
        org.ecolution.webpage.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Ecolution"))
    renderComposable(rootElementId = "root") {
        org.ecolution.webpage.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}

package org.ecolution.webpage.pages.blogs

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun FOUNDATIONPage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("FOUNDATION") {
        org.jetbrains.compose.web.dom.H3(attrs = { id("die-gründung-von-ecolution-ein-abenteuer-im-camper") }) {
            org.jetbrains.compose.web.dom.Text("Die Gründung von Ecolution: Ein Abenteuer im Camper")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Autor")
            }
            org.jetbrains.compose.web.dom.Text(": Max Mustermann")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Veröffentlichungsdatum")
            }
            org.jetbrains.compose.web.dom.Text(": 2024-09-28")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Kategorie")
            }
            org.jetbrains.compose.web.dom.Text(": Unternehmensgründung, Nachhaltigkeit")
            org.jetbrains.compose.web.dom.Br()
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Lesezeit")
            }
            org.jetbrains.compose.web.dom.Text(": 6 Minuten")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-traum-wurde-geboren") }) {
            org.jetbrains.compose.web.dom.Text("Ein Traum wurde geboren")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Geburtsstunde von Ecolution schlug am ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("10.06.2019")
            }
            org.jetbrains.compose.web.dom.Text(" in ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Silverthorne, Colorado, USA")
            }
            org.jetbrains.compose.web.dom.Text(", als den drei Gründern, inspiriert durch die Kräuter von Mutter Natur, während der Fahrt von Denver nach Silverthorne die beste Idee ihres Lebens kam. Diese Eingebung war so kraftvoll, dass sie sie sofort gemeinsam in die Tat umsetzten und begannen, in einem McDonald's einen Businessplan zu erstellen. Dieser wurde später weiter ausgearbeitet und verfeinert. Es war ein Moment der Erleuchtung und Manifestation zugleich – die Idee entstand und wurde gleichzeitig realisiert.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ein-moment-der-inspiration") }) {
            org.jetbrains.compose.web.dom.Text("Ein Moment der Inspiration")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Geschichte von Ecolution begann nicht in einem schicken Bürogebäude oder einem innovativen Co-Working-Space, sondern an einem Ort, der die Werte unseres Unternehmens wie kein anderer verkörpert: die weite, unberührte Natur von Colorado. Inmitten der atemberaubenden Landschaften der Rocky Mountains, umgeben von nichts als frischer Luft und der Ruhe der Wildnis, entstand die Vision, die den Grundstein für Ecolution legte.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Es war im Sommer 2020, während eines Roadtrips in einem Camper durch Colorado, als die Idee zu Ecolution weitergeführt wurde. Die Welt stand still. Die Pandemie hatte viele von uns gezwungen, innezuhalten und über den Zustand unseres Planeten nachzudenken. Wir saßen vor unserem kleinen Camper, unter dem sternenklaren Himmel, und sprachen über die Notwendigkeit eines Wandels.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("die-vision") }) {
            org.jetbrains.compose.web.dom.Text("Die Vision")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ecolution steht für eine Revolution im Bauwesen. Unsere Mission ist es, innovative und nachhaltige Lösungen für Gebäude zu entwickeln, die den Energieverbrauch minimieren und die Umwelt schonen. Von energieeffizienten Wohnhäusern bis hin zu großen nachhaltigen Bauprojekten – wir wollen die Zukunft des Bauens mitgestalten.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Unsere Reise begann in einem bescheidenen Camper, aber unsere Vision ist global. Mit einem Team von Experten, das sich der Idee verschrieben hat, die Welt zu einem besseren Ort zu machen, arbeiten wir täglich daran, diese Vision Realität werden zu lassen.")
        }
    }
}

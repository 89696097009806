package org.ecolution.webpage.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.whiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.WHITE
import org.ecolution.webpage.toFormattedTousenderSeparatorString
import org.ecolution.webpage.toSitePalette
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.dom.P

@Composable
fun CertificateBadge(text: String) {
    val color = ColorMode.current.toSitePalette().brand.certificateBadge

    Badge(text, color)
}

@Composable
fun EBFBadge(int: Int) {
    val color = ColorMode.current.toSitePalette().brand.ebfBadge
    val text = "EBF ${int.toFormattedTousenderSeparatorString()} m²"

    Badge(text, color)
}

@Composable
private fun Badge(text: String, backgroundcolor: CSSColorValue) {
    val textColor = WHITE
    Box(
        modifier = Modifier
            .backgroundColor(backgroundcolor)
            .padding(topBottom = 0.75.em)
            .margin(0.5.em)
            .width(10.em)
            .borderRadius(5.px)
            .fontSize(0.5.em),
        contentAlignment = Alignment.Center
    ) {
        SpanText(text, Modifier
            .color(textColor)
        )
    }
}
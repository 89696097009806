package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun BadenPage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("Stadt Baden") {
        org.ecolution.webpage.components.widgets.EcoSpacer()
        org.jetbrains.compose.web.dom.H3(attrs = { id("partnerschaft-mit-der-stadt-baden-gemeinsam-für-nachhaltige-energie") }) {
            org.jetbrains.compose.web.dom.Text("Partnerschaft mit der Stadt Baden: Gemeinsam für nachhaltige Energie")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ecolution Engineering freut sich über die enge Zusammenarbeit mit der Stadt Baden, die im Rahmen des städtischen Energiekonzepts 2022-2031 ins Leben gerufen wurde. Seit 2019 unterstützen wir als verlässlicher Partner private Haushalte und Unternehmen in Baden durch gezielte Energieberatungen. Unser Fokus liegt auf der Analyse und Optimierung von Gebäuden, um den Energieverbrauch zu senken und nachhaltige Lösungen voranzutreiben.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("gemeinsam-für-eine-grüne-zukunft") }) {
            org.jetbrains.compose.web.dom.Text("Gemeinsam für eine grüne Zukunft")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Die Kooperation mit der Stadt Baden ermöglicht es uns, unsere Expertise in den Bereichen von energetischen Sanierungen und nachhaltige Heizlösungen noch gezielter einzusetzen. Durch diese Partnerschaft leisten wir einen wesentlichen Beitrag zur Erreichung der lokalen Energieziele und fördern innovative Lösungen für die Zukunft.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ecolution-heute") }) {
            org.jetbrains.compose.web.dom.Text("Ecolution heute")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Heute sind wir stolz darauf, als wichtiger Akteur in der Energiewende sowohl im Kanton Aargau als auch in anderen Schweizer Kantonen zu agieren. Unser Ziel bleibt es, ökologisch sinnvolle und wirtschaftlich vorteilhafte Lösungen zu bieten, die sowohl der Umwelt als auch den Energieverbrauchern zugutekommen. Durch unsere Zusammenarbeit mit der Stadt Baden stärken wir unsere Position als Anbieter im Bereich der Energieberatung.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Mehr Informationen zu unserer Rolle als offizieller Energieberater finden Sie auf der ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://www.baden.ch/de/leben-wohnen/energie-mobilitaet/energie/beratung.html/721", "Website der Stadt Baden")
            org.jetbrains.compose.web.dom.Text(".")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(org.ecolution.webpage.data.Blog.Companion.blogs[0])
    }
}

package org.ecolution.webpage.components.header

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.ecolution.webpage.data.Company
import org.ecolution.webpage.data.Navigation.HOME_URL
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Img

@Composable
fun HomeLogo(colorMode: ColorMode) {
    Anchor(
        href = HOME_URL,
    ) {
        Box(Modifier.margin(4.px)) {
            when (colorMode) {

                ColorMode.DARK -> Img(
                    Company.Logo.DARK,
                    Company.Details.FULL_NAME,
                    Modifier.height(3.cssRem).display(DisplayStyle.Block).toAttrs()
                )

                ColorMode.LIGHT -> Img(
                    Company.Logo.LIGHT,
                    Company.Details.FULL_NAME,
                    Modifier.height(3.cssRem).display(DisplayStyle.Block).toAttrs()
                )
            }
        }
    }
}
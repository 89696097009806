package org.ecolution.webpage.data

object Navigation {
    const val SERVICES_NAME = "Dienstleistungen"
    const val HOME_URL = "/"

    const val ABOUT_URL = "/about"
    const val ABOUT_NAME = "Über uns"
    const val REFERENCES_URL = "/references"
    const val REFERENCES_NAME = "Referenzen"
}
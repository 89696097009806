package org.ecolution.webpage.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun TransformationPage() {
    org.ecolution.webpage.components.layouts.MarkdownLayout("Transformation") {
        org.ecolution.webpage.components.widgets.EcoSpacer()
        org.jetbrains.compose.web.dom.H3(attrs = { id("die-entwicklung-von-ecolution-vom-hlks-allrounder-zur-energiespezialisierung") }) {
            org.jetbrains.compose.web.dom.Text("Die Entwicklung von Ecolution: Vom HLKS Allrounder zur Energiespezialisierung")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("der-wandel-zur-energiespezialisierung") }) {
            org.jetbrains.compose.web.dom.Text("Der Wandel zur Energiespezialisierung")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Nach der erfolgreichen Gründung im Jahr 2019 begann Ecolution zunächst als vielseitiger Anbieter im Bereich der Planung und Beratung für HLKS-Systeme (Heizung, Lüftung, Klima und Sanitär). Unser Ziel war es, nachhaltige und energieeffiziente Lösungen für Neubauten und Sanierungen zu bieten. Durch enge Zusammenarbeit mit Bauherren, Architekten und Ingenieuren trugen wir dazu bei, Projekte zu realisieren die ökologische und wirtschaftliche Anforderungen gleichermassen berücksichtigten.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("fokussierung-auf-energieberatung-und-geak-plus") }) {
            org.jetbrains.compose.web.dom.Text("Fokussierung auf Energieberatung und GEAK Plus")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Mit der Zeit und wachsender Expertise erkannte Ecolution, dass der grösste Beitrag zur Reduktion des Energieverbrauchs in der ganzheitlichen Betrachtung von Gebäuden und deren energetischen Potentialen liegt. Aus diesem Grund verlagerten wir unseren Fokus zunehmend auf die Energieberatung. Insbesondere der ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("GEAK Plus")
            }
            org.jetbrains.compose.web.dom.Text(" (Gebäudeenergieausweis der Kantone) bot uns die Möglichkeit, unseren Kunden fundierte und detaillierte Analysen zur energetischen Effizienz ihrer Gebäude anzubieten.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Durch diese Spezialisierung konnten wir uns als Experten für ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("Gebäudeanalysen")
            }
            org.jetbrains.compose.web.dom.Text(" und ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("energetische Beratungen")
            }
            org.jetbrains.compose.web.dom.Text(" etablieren. Unser Angebot konzentriert sich heute auf die Erstellung von umfassenden Energieberichten, die Planung energetischer Optimierungen und die individuelle Beratung von Eigentümern und Unternehmen, die ihre Gebäude auf zukunftsfähige Standards bringen möchten.")
        }
        org.jetbrains.compose.web.dom.H4(attrs = { id("ecolution-heute") }) {
            org.jetbrains.compose.web.dom.Text("Ecolution heute")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Heute ist Ecolution ein führender Anbieter im Bereich der Energieberatung, mit einem klaren Fokus auf ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("GEAK Plus")
            }
            org.jetbrains.compose.web.dom.Text(", energetische Sanierungen und Gebäudeoptimierungen. Unser Team aus Experten für Energieeffizienz setzt sich dafür ein, dass unsere Kunden nicht nur von ökologischen, sondern auch von wirtschaftlichen Vorteilen profitieren. Dank unserer Erfahrung und Spezialisierung helfen wir ihnen, nachhaltige und kosteneffiziente Lösungen umzusetzen, die den Anforderungen der Zukunft gerecht werden.")
        }
        org.ecolution.webpage.components.widgets.BlogIntro(org.ecolution.webpage.data.Blog.Companion.blogs[0])
    }
}
